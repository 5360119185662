import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../hooks/ThemeContext";
import { UserContext } from "../hooks/UserContext";
import { Moon, Sun, Eye, Settings } from "lucide-react";
import FranceFlag from "../assets/images/fr.png"; // Import de l'image PNG
import UKFlag from "../assets/images/uk.png"; // Import de l'image PNG
import {
  FaSearch,
  FaShoppingCart,
  FaBars,
  FaTimes,
  FaChevronDown,
} from "react-icons/fa";
import { useTranslation } from "react-i18next"; // Importation du hook i18n

const Navbar = () => {
  const { user, isAuthenticated, loading, role } = useContext(UserContext);
  const { theme, setTheme, language, setLanguage, textSize, setTextSize } =
    useContext(ThemeContext);
  const { t, i18n } = useTranslation(); // Utilisation du hook pour la traduction
  const [searchQuery, setSearchQuery] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const toggleSettings = () => setSettingsOpen(!settingsOpen);

  const handleThemeChange = (newTheme) => setTheme(newTheme);

  const handleLanguageChange = (lang) => {
    localStorage.setItem("language", lang); // Enregistrer dans localStorage
    i18n.changeLanguage(lang); // Changer la langue dans i18next
    setLanguage(lang); // Mettre à jour l'état local si nécessaire
  };

  const closeMenus = () => {
    setDropdownOpen(false);
    setSettingsOpen(false);
  };

  const handleLinkClick = () => {
    closeMenus();
  };

  const handleTextSizeChange = (e) => setTextSize(e.target.value);

  return (
    <nav className="sticky top-0 z-50 shadow-md">
      <div className="flex justify-between items-center px-8 py-4">
        <button
          onClick={toggleDrawer}
          className="lg:hidden text-3xl hover:opacity-80"
          aria-label="Ouvrir le menu"
        >
          {drawerOpen ? <FaTimes /> : <FaBars />}
        </button>

        <Link to="/" className="text-4xl font-extrabold">
          <span className="link1">GTA</span>
          <span className="link2">Market</span>
        </Link>

        <div className="hidden lg:flex items-center bg-white rounded-lg overflow-hidden w-[40rem]">
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="p-3 bg-transparent border-none outline-none"
          >
            <option value="">{t("allCategories")}</option>
            <option value="Mapping">Mapping</option>
            <option value="Loading">Loading Screen</option>
            <option value="Scripts">Scripts</option>
          </select>

          <input
            type="text"
            placeholder={t("searchProduct")}
            className="px-4 py-2 text-black outline-none w-full"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button className="px-4 buttonSearch">
            <FaSearch />
          </button>
        </div>

        <div className="flex items-center space-x-6 relative">
          <button
            onClick={toggleDropdown}
            className="flex items-center hover:opacity-80"
            aria-label="Ouvrir le menu utilisateur"
          >
            <span className="ml-2">
              {t("helloSignIn")}{" "}
              {isAuthenticated && (user?.name || user?.nickname)}
            </span>

            <FaChevronDown className="ml-2" />
          </button>

          {dropdownOpen && (
            <div className="absolute right-15 top-4 mt-12 bg-white shadow-lg rounded-lg p-4 w-48 text-black">
              {isAuthenticated ? (
                <>
                  <Link
                    to="/profile"
                    onClick={handleLinkClick}
                    className="block px-4 py-2 hover:bg-[#EC5B2F] hover:text-white"
                  >
                    {t("myAccount")}
                  </Link>
                  <Link
                    to="/orders"
                    onClick={handleLinkClick}
                    className="block px-4 py-2 hover:bg-[#EC5B2F] hover:text-white"
                  >
                    {t("myOrders")}
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to="/register"
                    onClick={handleLinkClick}
                    className="block px-4 py-2"
                  >
                    {t("signUp")}
                  </Link>
                  <Link
                    to="/login"
                    onClick={handleLinkClick}
                    className="block px-4 py-2"
                  >
                    {t("signIn")}
                  </Link>
                </>
              )}
            </div>
          )}

          <Link to="/cart" className="relative hover:opacity-80">
            <FaShoppingCart className="text-3xl cart" />
            <span className="absolute -top-1 -right-3 text-sm px-2 rounded-full quantityCart">
              0
            </span>
          </Link>

          <button
            onClick={toggleSettings}
            className="relative hover:opacity-80"
            aria-label="Ouvrir les paramètres"
          >
            <Settings className="text-3xl" />
          </button>

          {settingsOpen && (
            <div className="absolute right-0 top-14 bg-white shadow-lg rounded-lg p-4 w-60 text-black">
              <h3 className="font-semibold mb-2">{t("settings")}</h3>
              <label className="block mb-2">
                {t("language")}:
                <div className="flex items-center space-x-2 mt-2">
                  <button
                    onClick={() => {
                      handleLanguageChange("fr");
                      closeMenus();
                    }}
                    className="flex items-center space-x-2 p-2 rounded hover:bg-gray-100"
                  >
                    <img src={FranceFlag} alt="France" className="w-6 h-4" />
                    <span>{t("french")}</span>
                  </button>
                  <button
                    onClick={() => {
                      handleLanguageChange("en");
                      closeMenus();
                    }}
                    className="flex items-center space-x-2 p-2 rounded hover:bg-gray-100"
                  >
                    <img src={UKFlag} alt="UK" className="w-6 h-4" />
                    <span>{t("english")}</span>
                  </button>
                </div>
              </label>
              <label className="block mb-2">
                {t("textSize")}:
                <select
                  value={textSize}
                  onChange={(e) => {
                    handleTextSizeChange(e);
                    closeMenus();
                  }}
                  className="ml-2 p-1 border rounded"
                >
                  <option value="small">{t("small")}</option>
                  <option value="normal">{t("normal")}</option>
                  <option value="large">{t("large")}</option>
                </select>
              </label>
              <div className="flex justify-around mt-4">
                <button
                  className="mx-4"
                  onClick={() => {
                    handleThemeChange("light");
                    closeMenus();
                  }}
                >
                  <Sun />
                </button>
                <button
                  className="mx-4"
                  onClick={() => {
                    handleThemeChange("dark");
                    closeMenus();
                  }}
                >
                  <Moon />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};


export default Navbar;
