import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Définition des traductions
const resources = {
  fr: {
    translation: {
      welcome: "Bienvenue sur GTA Market",
      login: "Se connecter",
      register: "S'inscrire",
      cart: "Panier",
      profile: "Mon Compte",
      explore_market: "Explorez notre marché et trouvez des produits uniques.",
      searchProduct: "Rechercher un produit",
      helloSignIn: "Bonjour, identifiez-vous",
      allCategories: "Toutes les catégories",
      myAccount: "Mon Compte",
      myOrders: "Mes Commandes",
      signUp: "Inscription",
      signIn: "Se Connecter",
      settings: "Paramètres",
      language: "Langue",
      textSize: "Taille du texte",
      small: "Petit",
      normal: "Normal",
      large: "Grand",
      french: "Français",
      english: "Anglais",
      // Textes pour la page d'accueil
      welcome_to_gta_market: "Bienvenue sur le GTA Market",
      explore_the_best_market_for_gta_services:
        "Explorez le meilleur marché pour les services de GTA",
      explore_market: "Explorez le marché",
      why_choose_us: "Pourquoi nous choisir",
      discover_why_we_are_the_best:
        "Découvrez pourquoi nous sommes les meilleurs",
      key_features: "Caractéristiques clés",
      secure_transactions: "Transactions sécurisées",
      safe_and_reliable_transaction_process:
        "Un processus de transaction sûr et fiable",
      easy_navigation: "Navigation facile",
      user_friendly_interface_for_all_users:
        "Une interface conviviale pour tous les utilisateurs",
      support_24_7: "Support qui répond sous 24 heures",
      trusted_by_community: "La confiance de la communauté",
      our_team_is_here_to_help_you: "Notre équipe est là pour vous aider",
      ready_to_start: "Prêt à commencer",
      join_the_best_in_gta_market:
        "Rejoignez dès maintenant la meilleure plateforme de marché GTA",
      sign_up_now: "Inscrivez-vous maintenant",
      discover_more: "Découvrez plus",
      exclusive_offers: "Offres exclusives",
      new_arrivals: "Nouveautés",
      best_sellers: "Meilleures ventes",
      community_forum: "Forum communautaire",
      ready_to_start: "Prêt à commencer ?",
      join_the_best_gta_market_platform_now:
        "Rejoignez dès maintenant la meilleure plateforme GTA",
      sign_up_now: "Inscrivez-vous maintenant",
      discover_more: "En savoir plus",
      access_special_deals_only_for_members:
        "Accédez à des offres spéciales réservées aux membres",
      check_out_the_latest_gta_services_and_products:
        "Découvrez les derniers services et produits GTA",
      explore_top_rated_services_and_products:
        "Explorez les services et produits les mieux notés",
      join_our_forum_and_interact_with_community:
        "Rejoignez notre forum et interagissez avec la communauté",

      // Pages de produit
      product_details: "Détails du produit",
      add_to_cart: "Ajouter au panier",
      product_description: "Description du produit",
      product_reviews: "Avis sur le produit",
      write_review: "Écrire un avis",
      price: "Prix",
      quantity: "Quantité",
      total_price: "Prix total",
      checkout: "Passer à la caisse",
      no_products_found: "Aucun produit trouvé",
      category: "Catégorie",
      tags: "Étiquettes",
      // Confirmation de commande
      order_confirmation: "Confirmation de commande",
      thank_you_for_your_order: "Merci pour votre commande",
      order_number: "Numéro de commande",
      shipping_address: "Adresse de livraison",
      payment_method: "Méthode de paiement",
      order_summary: "Récapitulatif de la commande",
      order_details: "Détails de la commande",
      // Erreurs et messages
      error: "Une erreur est survenue. Veuillez réessayer.",
      user_not_found: "Utilisateur non trouvé.",
      invalid_credentials: "Identifiants invalides.",
      session_expired: "Session expirée. Veuillez vous reconnecter.",
      // Textes de notification
      new_message: "Nouveau message",
      product_added_to_cart: "Produit ajouté au panier",
      product_removed_from_cart: "Produit retiré du panier",
      account_updated: "Compte mis à jour avec succès",
      password_changed: "Mot de passe modifié avec succès",
      account_deleted: "Compte supprimé avec succès",
      // Paramètres du compte
      update_profile: "Mettre à jour le profil",
      change_password: "Changer le mot de passe",
      delete_account: "Supprimer le compte",
      current_password: "Mot de passe actuel",
      new_password: "Nouveau mot de passe",
      confirm_password: "Confirmer le mot de passe",
      save_changes: "Sauvegarder les modifications",
      cancel: "Annuler",
      // Autres textes
      contact_us: "Contactez-nous",
      privacy_policy: "Politique de confidentialité",
      terms_of_service: "Conditions d'utilisation",
      faq: "FAQ",
      about_us: "À propos de nous",
      follow_us: "Suivez-nous",
    },
  },
  en: {
    translation: {
      welcome: "Welcome to GTA Market",
      login: "Login",
      register: "Register",
      cart: "Cart",
      profile: "My Account",
      explore_market: "Explore our market and find unique products.",
      searchProduct: "Search for a product",
      helloSignIn: "Hello, sign in",
      allCategories: "All categories",
      myAccount: "My Account",
      myOrders: "My Orders",
      signUp: "Sign Up",
      signIn: "Sign In",
      settings: "Settings",
      language: "Language",
      textSize: "Text Size",
      small: "Small",
      normal: "Normal",
      large: "Large",
      french: "French",
      english: "English",
      // Texts for the homepage
      welcome_to_gta_market: "Welcome to the GTA Market",
      explore_the_best_market_for_gta_services:
        "Explore the best market for GTA services",
      explore_market: "Explore the Market",
      why_choose_us: "Why Choose Us",
      discover_why_we_are_the_best: "Discover why we are the best",
      key_features: "Key Features",
      secure_transactions: "Secure Transactions",
      safe_and_reliable_transaction_process:
        "A safe and reliable transaction process",
      easy_navigation: "Easy Navigation",
      user_friendly_interface_for_all_users:
        "A user-friendly interface for all users",
      support_24_7: "24/7 Support",
      trusted_by_community: "Trusted by communit",
      our_team_is_here_to_help_you: "Our team is here to help you",
      ready_to_start: "Ready to Start",
      join_the_best_in_gta_market: "Join the best GTA market platform now",
      sign_up_now: "Sign Up Now",
      discover_more: "Discover More",
      exclusive_offers: "Exclusive Offers",
      new_arrivals: "New Arrivals",
      best_sellers: "Best Sellers",
      community_forum: "Community Forum",
      ready_to_start: "Ready to Start ?",
      join_the_best_gta_market_platform_now:
        "Join the best GTA market platform now",
      sign_up_now: "Sign Up Now",
      discover_more: "Discover more",
      access_special_deals_only_for_members:
        "Access special deals only for members",
      check_out_the_latest_gta_services_and_products:
        "Check out the latest GTA services and products",
      explore_top_rated_services_and_products:
        "Explore top-rated services and products",
      join_our_forum_and_interact_with_community:
        "Join our forum and interact with the community",

      // Product page
      product_details: "Product Details",
      add_to_cart: "Add to Cart",
      product_description: "Product Description",
      product_reviews: "Product Reviews",
      write_review: "Write a Review",
      price: "Price",
      quantity: "Quantity",
      total_price: "Total Price",
      checkout: "Checkout",
      no_products_found: "No products found",
      category: "Category",
      tags: "Tags",
      // Order confirmation
      order_confirmation: "Order Confirmation",
      thank_you_for_your_order: "Thank you for your order",
      order_number: "Order Number",
      shipping_address: "Shipping Address",
      payment_method: "Payment Method",
      order_summary: "Order Summary",
      order_details: "Order Details",
      // Errors and messages
      error: "An error occurred. Please try again.",
      user_not_found: "User not found.",
      invalid_credentials: "Invalid credentials.",
      session_expired: "Session expired. Please log in again.",
      // Notification texts
      new_message: "New message",
      product_added_to_cart: "Product added to cart",
      product_removed_from_cart: "Product removed from cart",
      account_updated: "Account updated successfully",
      password_changed: "Password changed successfully",
      account_deleted: "Account deleted successfully",
      // Account settings
      update_profile: "Update profile",
      change_password: "Change password",
      delete_account: "Delete account",
      current_password: "Current password",
      new_password: "New password",
      confirm_password: "Confirm password",
      save_changes: "Save changes",
      cancel: "Cancel",
      // Other texts
      contact_us: "Contact us",
      privacy_policy: "Privacy Policy",
      terms_of_service: "Terms of Service",
      faq: "FAQ",
      about_us: "About Us",
      follow_us: "Follow us",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("language") || "fr", // Langue par défaut
  fallbackLng: "fr", // Langue par défaut si la langue demandée n'est pas trouvée
  interpolation: {
    escapeValue: false, // Permet d'utiliser du HTML dans les traductions
  },
  react: {
    useSuspense: false, // Désactive l'utilisation de Suspense dans les traductions
  },
});

export default i18n;
