import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FaShieldAlt,
  FaShoppingCart,
  FaHeadset,
  FaUsers,
} from "react-icons/fa";
import { motion } from "framer-motion";
import Slider from "react-slick";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Home = () => {
  const { t } = useTranslation();
  const [latestProducts, setLatestProducts] = useState([]);

  useEffect(() => {
    axios
      .get("http://localhost:8000/api/products/latest")
      .then((response) => setLatestProducts(response.data))
      .catch((error) => console.error(error));
  }, []);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <div className="min-h-screen flex flex-col bg-[#a7b4b7] text-[#1D3557]">
      {/* Hero Section */}
      <header
        className="relative w-full h-screen flex items-center justify-center text-center bg-cover bg-center"
        style={{ backgroundImage: "url(/path/to/your-image.jpg)" }}
      >
        <div className="absolute inset-0 bg-[#a7b4b7]  opacity-60"></div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="relative z-10 max-w-2xl"
        >
          <h1 className="text-5xl sm:text-6xl font-extrabold mb-6">
            {t("welcome_to_gta_market")}
          </h1>
          <p className="text-lg sm:text-xl mb-8 opacity-80">
            {t("explore_the_best_market_for_gta_services")}
          </p>
          <a
            href="#features"
            className="bg-[#ec5b2f] text-white px-8 py-3 rounded-lg shadow-lg hover:bg-[#1D3557] transition duration-300"
          >
            {t("explore_market")}
          </a>
        </motion.div>
      </header>

      {/* Features Section */}
      <section
        id="features"
        className="py-16 bg-[#1d3557] text-white text-center"
      >
        <div className="container mx-auto">
          <h2 className="text-4xl sm:text-5xl font-semibold mb-8">
            {t("key_features")}
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
            {[
              {
                icon: <FaShieldAlt />,
                title: t("secure_transactions"),
                desc: t("safe_and_reliable_transaction_process"),
              },
              {
                icon: <FaShoppingCart />,
                title: t("easy_navigation"),
                desc: t("user_friendly_interface_for_all_users"),
              },
              {
                icon: <FaHeadset />,
                title: t("support_24_7"),
                desc: t("our_team_is_here_to_help_you"),
              },
              {
                icon: <FaUsers />,
                title: t("trusted_by_community"),
                desc: t("join_the_best_in_gta_market"),
              },
            ].map((feature, index) => (
              <motion.div
                key={index}
                className="bg-white text-black p-6 rounded-lg shadow-lg transform hover:scale-105 transition duration-300"
                whileHover={{ scale: 1.05 }}
              >
                <div className="text-[#ec5b2f] text-4xl mb-4">
                  {feature.icon}
                </div>
                <h3 className="text-2xl font-semibold">{feature.title}</h3>
                <p className="mt-4 text-gray-700">{feature.desc}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Derniers Produits */}
      <section className="py-16 bg-gray-900 text-center">
        <h2 className="text-4xl font-semibold mb-8 text-white">
          {t("latest_products")}
        </h2>
        <div className="container mx-auto">
          <Slider {...sliderSettings}>
            {latestProducts.map((product) => (
              <motion.div
                key={product.id}
                className="bg-white rounded-lg shadow-lg overflow-hidden mx-2"
                whileHover={{ scale: 1.05 }}
              >
                <img
                  src={product.picture_product}
                  alt={product.name_product}
                  className="w-full h-48 object-cover"
                />
                <div className="p-4 text-gray-900">
                  <h3 className="text-xl font-semibold">
                    {product.name_product}
                  </h3>
                  <p className="text-red-600 font-bold mt-2">
                    {product.price} €
                  </p>
                  <p className="text-gray-700 mt-2">
                    {product.description_product.slice(0, 50)}...
                  </p>
                  <a
                    href={`/product/${product.id}`}
                    className="block mt-4 bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition duration-300"
                  >
                    {t("view_product")}
                  </a>
                </div>
              </motion.div>
            ))}
          </Slider>
        </div>
      </section>

      {/* CTA Section */}
      <section className="text-center py-16 bg-[#1d3557] text-white">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-4xl font-semibold mb-4"
        >
          {t("ready_to_start")}
        </motion.h2>
        <p className="text-lg opacity-80 mb-6">
          {t("join_the_best_gta_market_platform_now")}
        </p>
        <motion.a
          whileHover={{ scale: 1.1 }}
          className="bg-red-600 text-white px-8 py-3 rounded-lg shadow-lg hover:bg-red-700 transition duration-300"
          href="/register"
        >
          {t("sign_up_now")}
        </motion.a>
      </section>
    </div>
  );
};

export default Home;
