import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  // Récupération des valeurs depuis le localStorage ou valeurs par défaut
  const getStoredValue = (key, defaultValue) => {
    const saved = localStorage.getItem(key);
    try {
      return saved ? JSON.parse(saved) : defaultValue;
    } catch {
      return defaultValue;
    }
  };

  const [theme, setTheme] = useState(() => getStoredValue("theme", "light"));
  const [language, setLanguage] = useState(() =>
    getStoredValue("language", "fr")
  );
  const [textSize, setTextSize] = useState(() =>
    getStoredValue("textSize", "normal")
  );

  // Fonction pour changer le thème (cycle entre light -> eye -> dark)
  const toggleTheme = () => {
    setTheme((prevTheme) => {
      if (prevTheme === "dark") return "light";
      if (prevTheme === "light") return "eye";
      return "dark";
    });
  };

  // Mise à jour du localStorage et du DOM lors des changements
  useEffect(() => {
    localStorage.setItem("theme", JSON.stringify(theme));
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  useEffect(() => {
    localStorage.setItem("language", JSON.stringify(language));
  }, [language]);

  useEffect(() => {
    localStorage.setItem("textSize", JSON.stringify(textSize));
    document.documentElement.style.fontSize =
      textSize === "large" ? "18px" : textSize === "small" ? "12px" : "16px";
  }, [textSize]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
        toggleTheme,
        language,
        setLanguage,
        textSize,
        setTextSize,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
