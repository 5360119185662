import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { I18nextProvider, useTranslation } from "react-i18next";
import Home from "../../pages/Home/index";
import Orders from "../../pages/orders";
import Footer from "../Footer";
import Navbar from "../Navbar";
import i18n from "../languages/i18n";
import Register from "../../pages/Auth/Register";
import Login from "../../pages/Auth/Login";

// Composant pour changer la langue

const RouteContainer = () => {
  // Initialiser la langue à partir du localStorage
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "fr"; // Langue par défaut
    i18n.changeLanguage(savedLanguage); // Applique la langue
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Navbar />
        <div className="root">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/orders" element={<Orders />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </I18nextProvider>
  );
};

export default RouteContainer;
