import { createGlobalStyle } from "styled-components";
import { useContext, useEffect } from "react";
import { ThemeContext } from "./ThemeContext";

// Définition du thème global avec des couleurs modulaires et adaptatives
const theme = {
  light: {
    outerSpace: "#3F555D", // Gris foncé
    ashGrey: "#A7B4B7", // Gris clair
    antiFlashWhite: "#E4EAEA", // Gris blanc
    flame: "#EC5B2F", // orange petant
    berkeleyBlue: "#1D3557", // bleu foncé
    black: "#aaaaaa", // noir
    white: "#ffffff", // Blanc
  },
  dark: {
    outerSpace: "#3F555D",
    ashGrey: "#A7B4B7",
    antiFlashWhite: "#E4EAEA",
    flame: "#EC5B2F",
    berkeleyBlue: "#000000",
    black: "#aaaaaa",
    white: "#ffffff",
  },
  eye: {
    outerSpace: "#3F555D", // Gris foncé
    ashGrey: "#A7B4B7", // Gris clair
    antiFlashWhite: "#E4EAEA", // Gris blanc
    flame: "#EC5B2F", // orange petant
    berkeleyBlue: "#1D3557", // bleu foncé
    black: "#aaaaaa", // noir
    white: "#ffffff", // Blanc
  },
};

const StyledGlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    background-color: ${({ theme }) => theme.ashGrey};
    color: ${({ theme }) => theme.black};
    font-family: 'Roboto', sans-serif;
    transition: background-color 0.3s, color 0.3s;
  }


nav{
  background-color: ${({ theme }) => theme.berkeleyBlue};
    color: ${({ theme }) => theme.flame};
}

.link1{
    color: ${({ theme }) => theme.flame};
}
.link2 {
    color: ${({ theme }) => theme.white};
}

select {
  background-color: transparent;
  color: inherit;
  border: none;
  outline: none;
}


select:focus,
select:hover {
  outline: none !important;
  box-shadow: none !important;
}


 option {
  background-color:  ${({ theme }) => theme.white} !important;
  color:  ${({ theme }) => theme.flame} !important;

  option:hover {
   background-color: ${({ theme }) => theme.flame} !important;
    color: ${({ theme }) => theme.text}  !important;
    }
}

  .button1 {
    background-color: ${({ theme }) => theme.berkeleyBlue};
    color: ${({ theme }) => theme.white};
    border: none;
    padding: 12px 24px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    &:hover {
     color: ${({ theme }) => theme.flame};
      background-color: ${({ theme }) => theme.berkeleyBlue};
    }
  }

  .buttonSearch {
    color: ${({ theme }) => theme.berkeleyBlue};
    border: none;
    padding: 12px 24px
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    &:hover {
       color: ${({ theme }) => theme.flame};
    }
  }

  .cart {
    color: ${({ theme }) => theme.white};
  }
  .quantityCart {
  background-color: ${({ theme }) => theme.flame};
    color: ${({ theme }) => theme.berkeleyBlue};
  }

  .backgroundPrimary {
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.text};
  }

  .backgroundSecondary {
    background-color: ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.text};
  }

  .textPrimary {
    color: ${({ theme }) => theme.text};
  }

  .textAccent {
    color: ${({ theme }) => theme.accent};
  }

  .card {
    background-color: ${({ theme }) => theme.secondary};
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
 
  a {
    color: ${({ theme }) => theme.primary};
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: ${({ theme }) => theme.accent};
    }
  }

 


  .link {
  background-color: ${({ theme }) => theme.secondary};
    &:hover {
       color: ${({ theme }) => theme.flame};
    }
  }
  .container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }

  header {
    background-color: ${({ theme }) => theme.headerBackground};
    padding: 15px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
`;

export default StyledGlobalStyle;

export const GlobalStyle = () => {
  const { theme: currentTheme } = useContext(ThemeContext);

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", currentTheme);
  }, [currentTheme]);

  return <StyledGlobalStyle theme={theme[currentTheme]} />;
};
