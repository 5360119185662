import React, { useContext } from "react";
import { ThemeContext } from "../hooks/ThemeContext"; // Importer le contexte de thème
import { FaDiscord, FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa"; // Utiliser FaTiktok à la place de FaTikTok

const Footer = () => {
  const { theme } = useContext(ThemeContext); // Récupérer le thème actuel du contexte

  return (
    <footer
      className="w-full py-8 mt-12"
      style={{
        backgroundColor: theme.ashGrey,
        color: theme.text, // Appliquer la couleur du texte depuis le thème
      }}
    >
      <div className="container mx-auto text-center space-y-6">
        {/* Texte principal */}
        <p className="text-sm sm:text-base">
          &copy; 2025 GTA Market. Tous droits réservés. Ludolpr Society
        </p>

        {/* Liens */}
        <div className="space-x-6 text-sm sm:text-base">
          <a
            href="/privacy"
            style={{ color: theme.text }}
            className="hover:text-gray-400 transition duration-300 ease-in-out"
          >
            Politique de confidentialité
          </a>
          <a
            href="/terms"
            style={{ color: theme.text }}
            className="hover:text-gray-400 transition duration-300 ease-in-out"
          >
            Conditions d'utilisation
          </a>
        </div>

        {/* Icônes sociales */}
        <div className="flex justify-center space-x-8 mt-6">
          <a
            href="#"
            className="text-2xl hover:text-purple-600 transition-all duration-300 ease-in-out transform hover:scale-110"
          >
            <FaDiscord />
          </a>
          <a
            href="#"
            className="text-2xl hover:text-black transition-all duration-300 ease-in-out transform hover:scale-110"
          >
            <FaTiktok />
          </a>
          <a
            href="#"
            className="text-2xl hover:text-pink-600 transition-all duration-300 ease-in-out transform hover:scale-110"
          >
            <FaInstagram />
          </a>
          <a
            href="#"
            className="text-2xl hover:text-red-700 transition-all duration-300 ease-in-out transform hover:scale-110"
          >
            <FaYoutube />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
